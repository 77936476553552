import { BsArrowLeft } from "react-icons/bs";
import React, { useEffect, useState } from 'react'
import { Col, Row, Image } from 'react-bootstrap'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { STRAPI_BLOG_SINGLE, STRAPI_IMAGE, StripeAxios, STRAPI_API_HOST } from '../../constants/strapi-api-constant'
import { BiCalendar } from "react-icons/bi";
import { AiOutlineFolderOpen } from "react-icons/ai";
import { BiUser } from "react-icons/bi";
import moment from "moment";
import Loader from "../../components/Loader";
import Seo from './../../components/Seo'

const BlogDetail = () => {
  const history = useNavigate();
  const { slug } = useParams();
  const [blog, setBlog] = useState({});
  const [loading, setLoading] = useState(false)
  const [metaData, setMetaData] = useState({});



  useEffect(() => {
    window.scrollTo(0, 0);
    setMetaData(JSON.parse(localStorage.getItem('blogSeo')) || {})
  }, []);



  useEffect(() => {
    getSingleBlog()
  }, [slug]);

  const getSingleBlog = async () => {
    try {
      setLoading(true)
      await StripeAxios.get(`${STRAPI_API_HOST}/blogs/${slug}?populate=*`).then(res => {
        setLoading(false)
        const { data } = res.data;
        if (data) {
          setBlog(data)
        } else {
          history('/404')
        }
        // console.log("data blog", data)
      })
        .catch(err => {
          setLoading(false);
          const { data } = err?.response;
          if (data.data == null) {
            history('/404')
          }
          console.log("blog error console", data?.error)
        });

    } catch (error) {
      setLoading(false)
      console.log("bogs api error", error);
    }
  }

  return (

    <>
      {loading && <Loader />}
      <Seo metaData={metaData} />
      <div className='terms_and_condition'>
        <header className="header-inner2" style={{ background: `url(${STRAPI_IMAGE + blog?.attributes?.HeaderImage?.data?.attributes?.url}`, backgroundSize: "cover", backgroundPosition: 'center', backgroundAttachment: "fixed" }}>
          <Row>
            <Col md={10} className="mx-auto">
              <h1 className="text-center">{blog?.attributes?.Title}</h1>
            </Col>
          </Row>
        </header>

        <div className='blogs_section'>
          <Row>

            <Col xs={12} sm={12} md={11} lg={10} className="mx-auto">
              <div className='term_box'>
                <Row className='d-flex justify-content-between my-5'>
                  <Col xs={12} sm={12} md={12} lg={12} xl={4}>
                    <BsArrowLeft onClick={() => history('/blogs')} className="back mb-2" />
                    <Image src={STRAPI_IMAGE + blog?.attributes?.Image?.data?.attributes?.url} className='img-fluid mb-4' />

                    <ul className="auther_list">
                      {/* <li>
                        <BiUser />
                        <p> {blog?.attributes?.Author}</p>
                      </li> */}
                      <li>
                        <BiCalendar />
                        <p>Published on {moment(blog?.attributes?.publishedAt).format('MMMM  D , YYYY')}</p>
                      </li>
                      <li>
                        <AiOutlineFolderOpen />
                        <p> Blogs | {blog?.attributes?.Category}</p>
                      </li>

                    </ul>

                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                    <div className='blog-detail'>
                      {/* <h5 className="mt-4">{blog?.attributes?.Title}</h5> */}


                      {blog?.attributes?.Description?.map((item, i) => {

                        if (item.type === "heading") {
                          return (<h4 key={i}>{item?.children[0]?.text}</h4>)
                        } else if (item.type === "paragraph") {
                          if (item?.children[0]?.text != '') {

                            return <p key={i}>
                              {item?.children.map((pra) => {
                                if (pra?.type == "link") {
                                  return <a href={pra?.url} target="_blank">{pra?.children[0]?.text}</a>
                                } else if (pra?.type == "text" && pra?.bold === true) {
                                  return <strong>{pra.text}</strong>
                                } else if (pra?.type == "text") {
                                  return pra.text;
                                }
                              })}
                            </p>
                          }


                        } else {
                          return (
                            <ul>
                              {item?.children?.map((list, l) => (
                                <li>
                                  {list?.children.map((pra) => {
                                    if (pra?.type == "link") {
                                      return <a href={pra?.url} target="_blank">{pra?.children[0]?.text}</a>
                                    } else if (pra?.type == "text" && pra?.bold === true) {
                                      return <strong>{pra.text}</strong>
                                    } else if (pra?.type == "text") {
                                      return pra.text;
                                    }
                                  })}
                                  {/* {list?.children[0].text} */}
                                </li>
                              ))}
                            </ul>
                          )
                        }
                      })}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div >
    </>
  )
}

export default BlogDetail