import React, { useEffect, useState } from 'react'
import { NEW_NEWSLETTER, StripeAxios } from '../../constants/strapi-api-constant';
import { Col, Modal, Row, Spinner } from 'react-bootstrap';
import { AiFillCloseCircle } from 'react-icons/ai';
import { SUBMIT_NEWSLETTER_SUBSCRIBE } from '../../constants/api-routes.constant';
import { IoIosArrowRoundForward } from 'react-icons/io';


const NewsLetter = () => {

    const EmailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    const [newNewsLetter, setNewNewsLetter] = useState({});

    // Subcribtion
    const [email, setEmail] = useState("");
    const [emailErr, setEmailErr] = useState("");
    const [load, setLoad] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [queryErr, setqueryErr] = useState("");

    // Modal
    const [show, setShow] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setqueryErr("");
        setLoad(true);
        let flag = false;
        if (!email) {
            setEmailErr("Please Enter Email Address");
            setLoad(false);
            flag = true;
        } else if (!EmailRegex.test(email)) {
            setEmailErr("Enter a valid email address");
            setLoad(false);
            flag = true;
        } else {
            setEmailErr("");
        }

        if (!flag) {
            fetch(SUBMIT_NEWSLETTER_SUBSCRIBE, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: email,
                }),
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    setqueryErr("Error: Invalid Username or Password provided.");
                })
                .then((response) => {
                    if (response && response?.statusCode === 200) {
                        setLoad(false);
                        setSuccessMessage("Subscribed Successfully");
                        setShow(true);
                        setTimeout(() => {
                            setSuccessMessage("");
                            setShow(false);
                        }, 5000);
                        setEmail("");
                    }
                })
                .catch((e) => {
                    setLoad(false);
                    setqueryErr("Error: Invalid Username or Password provided.");
                });
        }
    };

    const handleClose = () => setShow(false);

    const ShowModalMessage = () => {
        return (
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                className="glassModel"
            >
                <Modal.Body>
                    <Row className="d-flex justify-content-end mb-2">
                        <Col md={1}>
                            <AiFillCloseCircle onClick={handleClose} cursor={"pointer"} />
                        </Col>
                    </Row>
                    <div className="newsletter_message_modal">
                        <h3>Thank You</h3>
                        <h1>For subscribing to the Skipify.ai Newsletter!</h1>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    useEffect(() => {
        getNewNewsLetter();
    }, []);

    const getNewNewsLetter = async () => {
        try {
            // setLoading(true)
            await StripeAxios.get(NEW_NEWSLETTER).then(res => {
                const { data } = res.data;
                setNewNewsLetter(data?.attributes);
            }).catch(err => {
                console.log('New Newsletter =>', err)
            });
        } catch (error) {
            console.log("New Newsletter  =>", error);
        }
    }
    return (
        <div className="container newsletter">
            <div className="row justify-content-center">
                <div className="col-md-12 col-lg-6 col align-self-center ">
                    <h1>{newNewsLetter?.Heading}</h1>
                </div>

                <div className='col-xs-12 col-md-12 col-lg-6'>

                    <div className='row'>
                        <div className="col-md-8 col-12 ">
                            <input placeholder="Enter Email Address" className="input-search-place"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />

                        </div>


                        <div className="col-md-4 col-12 align-self-center ">
                            <div className="actions">
                                <button className="primary" onClick={handleSubmit}>
                                    {load ? <Spinner animation="border" size="sm" /> : 'Subscribe'}
                                </button>

                            </div>
                        </div>
                        <div className='col-md-12 '>
                            {successMessage && (
                                <span className="text-success">{successMessage}</span>
                            )}
                            {queryErr ||
                                (emailErr && (
                                    <span className="text-danger mx-3">
                                        {queryErr} {emailErr}
                                    </span>
                                ))}
                        </div>
                    </div>

                </div>

            </div>
            {ShowModalMessage()}
        </div>
    )
}

export default NewsLetter