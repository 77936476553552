import { Modal } from 'react-bootstrap';
import React, { useEffect, useState } from "react";

import {
    StripeAxios,
    NEW_PRE_FORECLOSURE_PAGE,
} from '../../constants/strapi-api-constant';

import './../../assets/new-homepage/css/mystyle.css';
import Seo from '../../components/Seo';
import Loader from '../../components/Loader';

import HeroSection from './section/HeroSection';
import PowerOfPredictive from './section/PowerOfPredictive';
import PreForeClosure from './section/PreForeClosure';
import FeatureForClosure from './section/FeatureForClosure';
import PreClosureFaq from './section/PreClosureFaq';
import Subscription from './section/Subscription';

const PreForeclosre = () => {


    const [show, setShow] = useState(false);
    const [url, setUrl] = useState('');
    const [content, setContent] = useState({});
    const [loading, setLoading] = useState(false)

    const handleClose = () => { setShow(false); setUrl('') };
    const handleShow = (url) => { setUrl(url); setShow(true) };

    const preClosureSection = async () => {
        try {
            setLoading(true)
            await StripeAxios.get(NEW_PRE_FORECLOSURE_PAGE).then(res => {
                setLoading(false)

                const { data } = res.data;
                setContent(data?.attributes);
            }).catch(err => {
                setLoading(false)
                console.log('PreClosure Page =>', err)
            });
        } catch (error) {
            console.log("PreClosure Page =>", error);
        }

    }

    useEffect(() => {
        preClosureSection();
    }, []);




    return <>
        <div className="container ai-power-dashboard">

            {loading ? <Loader /> :

                <>
                    <Seo metaData={content?.SEO} />
                    <Modal show={show} onHide={handleClose} className='videoModal' centered size="xl">
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                            <iframe className='mega-iframe' src="https://www.youtube.com/embed/QxFC_meeejA" title="How to Access Pre-Foreclosure &amp; Likely-to-Default (Propensity Data) on Skipify.ai" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </Modal.Body>
                    </Modal>

                    <HeroSection data={content?.HeroSection} handleShow={handleShow} />

                    <PowerOfPredictive data={content?.DescriptionVideo} />

                    <PreForeClosure data={content?.PreForeclosureOne} />
                    <PreForeClosure data={content?.PreForeclosureTwo} />
                    <FeatureForClosure heading={content?.FeatureSectionHeading} features={content?.Features} />

                    <PreClosureFaq heading={content?.AccordionSectionHeading} lists={content?.fore_cosure_faqs?.data} />
                    <Subscription data={content?.SubscriptionSection} />
                </>
            }


        </div>

    </>
}




export default PreForeclosre;