import axios from "axios";

export const STRAPI_API_HOST = process.env.REACT_APP_STRAPI_API_URL;

export const STRAPI_IMAGE = process.env.REACT_APP_STRAPI_IMAGE_URL;
// user Token
export const STRAPI_USER_TOKEN = process.env.REACT_APP_STRAPI_TOKEN;

axios.defaults.headers.common["Authorization"] = 'Baerer ' + STRAPI_USER_TOKEN;

export const StripeAxios = axios.create({
  baseURL: STRAPI_API_HOST,
  timeout: 30000
});





export const STRAPI_TERMS = `${STRAPI_API_HOST}/term-of-servies`;
export const STRAPI_CONTACT_SUPPORT = `${STRAPI_API_HOST}/contact-us`;
export const STRAPI_CONTACT_SALE = `${STRAPI_API_HOST}/contact-sale`;
export const STRAPI_MISSION_AND_VISION = `${STRAPI_API_HOST}/mission-and-vision?populate=*`;
export const STRAPI_PERSONAL_INFO = `${STRAPI_API_HOST}/personal-information`;
export const STRAPI_PRICING_PLAN = `${STRAPI_API_HOST}/pricing-plan`;
export const STRAPI_PRIVACY_POLICY = `${STRAPI_API_HOST}/privacy-policies`;
export const STRAPI_TERM_OF_SERVIES = `${STRAPI_API_HOST}/term-of-servies`;
export const STRAPI_ABOUT_TOP = `${STRAPI_API_HOST}/about-top?populate=*`;
export const STRAPI_ABOUT_SERVICE = `${STRAPI_API_HOST}/about-services?populate=*`;
export const STRAPI_BLOGS = `${STRAPI_API_HOST}/blogs?populate=*`;
export const STRAPI_BLOG_SINGLE = `${STRAPI_API_HOST}/blogs`;
export const STRAPI_SKIPIFY_TV = `${STRAPI_API_HOST}/skipify-tvs?populate=*`;
export const STRAPI_FREE_DOWNLOAD_ITEMS = `${STRAPI_API_HOST}/skipify-free-download-items?populate=*`;
export const STRAPI_REVIEW = `${STRAPI_API_HOST}/reviews?populate=*`;
export const STRAPI_SKIPIFY_U_CONTENT = `${STRAPI_API_HOST}/skipify-u-content?populate=*`;
export const STRAPI_CAMPAIGN_FREE_DOWNLOAD = `${STRAPI_API_HOST}/campaign-page-free-download-form?populate=*`;
export const STRAPI_HOME_TOP = `${STRAPI_API_HOST}/home-top?populate=*`;
export const STRAPI_OUR_SERVICES = `${STRAPI_API_HOST}/our-service?populate=home_our_services.Image`;
export const STRAPI_WHY_SKIPIFY = `${STRAPI_API_HOST}/why-skipify?populate=home_services.Image`;
export const STRAPI_PARTNER = `${STRAPI_API_HOST}/partner?populate=*`;
export const STRAPI_CREATE_CONTACT = `${STRAPI_API_HOST}/contacts`;
export const STRAPI_SUBMIT_CONTACT_SUPPORT = `${STRAPI_API_HOST}/contact-supports`;
export const STRAPI_SUBMIT_CONTACT_SALE = `${STRAPI_API_HOST}/contact-sale-massages`;
export const STRAPI_SUBMIT_FREE_DOWNLOAD_SUPPORT = `${STRAPI_API_HOST}/campaign-free-download-supports`;
export const STRAPI_SUBMIT_PERSONAL_INFO = `${STRAPI_API_HOST}/personal-info-messages`;

// SEO API
export const SEO_CONTENT_OF_HOME_PAGE = `${STRAPI_API_HOST}/seo-home-page?populate=*`;
export const SEO_CONTENT_OF_PRICING_PAGE = `${STRAPI_API_HOST}/seo-pricing-page?populate=*`;
export const SEO_CONTENT_OF_ABOUT_US = `${STRAPI_API_HOST}/seo-about-page?populate=*`;
export const SEO_CONTENT_OF_CONTACT_SUPPORT = `${STRAPI_API_HOST}/seo-contact-support-page?populate=*`;
export const SEO_CONTENT_OF_CONTACT_SALE = `${STRAPI_API_HOST}/seo-contact-sale-page?populate=*`;
export const SEO_CONTENT_OF_PERSONAL_INFO = `${STRAPI_API_HOST}/seo-personal-info-page?populate=*`;
export const SEO_CONTENT_OF_BLOG = `${STRAPI_API_HOST}/seo-blog-page?populate=*`;
export const SEO_CONTENT_OF_PRIVACY_POLICY = `${STRAPI_API_HOST}/seo-privacy-policy-page?populate=*`;
export const SEO_CONTENT_OF_TERMS = `${STRAPI_API_HOST}/seo-terms-page?populate=*`;
export const SEO_CONTENT_OF_AFFILIATE = `${STRAPI_API_HOST}/seo-affiliate-page?populate=*`;

// Affiliate page
export const AFFILIATE_HERO_SECTION = `${STRAPI_API_HOST}/affiliate-hero-section?populate=*`;
export const AFFILIATE_FAQS = `${STRAPI_API_HOST}/affiliate-faqs`;
export const AFFILIATE_HIGHLIGHTS_SECTION = `${STRAPI_API_HOST}/affiliate-highlights-section?populate=*`;
export const AFFILIATE_JOIN_SECTION = `${STRAPI_API_HOST}/affiliate-join-section?populate=*`;
export const AFFILIATE_REQUIRMENT_SECTION = `${STRAPI_API_HOST}/affiliate-requirements-section?populate=*`;


export const NEW_HERO_PAGE = `${STRAPI_API_HOST}/new-hero-section?populate=*`;
export const NEW_LEAD_GENERATION = `${STRAPI_API_HOST}/new-lead-generation?populate=*`;
export const NEW_MAX_LEAD_GENERATION = `${STRAPI_API_HOST}/new-maximize-lead-gen?populate=*`;
export const NEW_WHY_WE_STAND_OUT = `${STRAPI_API_HOST}/new-why-we-stand-out?populate=*`;
export const NEW_PAGE_REVIEWS = `${STRAPI_API_HOST}/new-page-review?populate=Client.Profile`;
export const NEW_MINI_SLIDER = `${STRAPI_API_HOST}/new-mini-slider?populate=*`;
export const NEW_BLOG_UPDATE = `${STRAPI_API_HOST}/new-blog-and-update`;
export const NEW_NEWSLETTER = `${STRAPI_API_HOST}/new-newsletter`;
export const NEW_VIDEOSECTION = `${STRAPI_API_HOST}/new-video-section?populate=MegaVideoSection.VideoWithDetailSection`;
export const NEW_STRAPI_BLOGS = `${STRAPI_API_HOST}/blogs?populate=*&sort[0]=publishedAt:desc&pagination[limit]=3`;
export const NEW_PRE_FORECLOSURE_PAGE = `${STRAPI_API_HOST}/pre-foreclosure-page?populate[HeroSection][populate]=Image&&populate[DescriptionVideo][populate]&&populate[PreForeclosureOne][populate]=Image&&populate[PreForeclosureTwo][populate]=Image&&populate[Features][populate]=Image&&populate[fore_cosure_faqs][populate]&&populate[SubscriptionSection][populate]=Image&&populate[SEO][populate]`;