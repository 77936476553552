import React from 'react'
import { STRAPI_IMAGE } from '../../../constants/strapi-api-constant'
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from 'react-router-dom';
const HeroSection = ({ data, handleShow }) => {
    const navigate = useNavigate();
    const ImgUrl = data?.Image.data && data?.Image?.data?.attributes?.url;
    return (
        <>
            <div className="grid-img mb-4">
                <div className="row justify-content-between py-4">
                    <div className="col-lg-5 col-md-12 align-self-center gradient-white">

                        <h1 className="heading">{data?.Heading ?? ''}</h1>
                        <div className="listing">
                            {data?.Description[0]?.type === "paragraph" ? <p>{data?.Description[0]?.children[0].text}</p> : ''}
                        </div>
                        <div className="actions">
                            <div className="row justify-context-start">
                                <div className="col">
                                    <button className="primary" onClick={() => navigate('/pricing-plan')}>Sign Up for Free</button>
                                    <button className="secondary mb-3" onClick={() => handleShow(1)}>Watch Tutorial</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <LazyLoadImage
                            src={STRAPI_IMAGE + ImgUrl}
                            effect="blur"
                            className='img-fluid'
                            alt="hero Image"
                        />
                    </div>
                </div>
            </div>

            <div className="sep-vertical"></div>
        </>
    )
}

export default HeroSection