import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import { STRAPI_ABOUT_SERVICE, StripeAxios, STRAPI_IMAGE } from '../../../constants/strapi-api-constant'
import Slider from "react-slick";

const WhyChooseUs = () => {

    useEffect(() => {
        getPageContent()
    }, []);


    const [content, setContent] = useState([]);

    const getPageContent = async () => {
        await StripeAxios.get(STRAPI_ABOUT_SERVICE)
            .then(res => {
                const { data } = res?.data;
                setContent(data);
            })
            .catch(err => console.log("why chose us", err))
    }


    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


    return (
        <div className='why_choose_us'>
            <Container>
                <Row>
                    <Col md={10} lg={8} className='mx-auto'>
                        <h1>Why Skipify.ai?</h1>
                        <p>Covering nearly 100% of the U.S. adult population, Skipify.ai’s data helps you generate more leads and make informed decisions for your real estate business.</p>
                    </Col>
                </Row>

                <Row className='mt-1'>
                    <div className='slider_div'>
                        <Slider {...settings}>
                            {content?.map((card, k) => (
                                <Col xs={12} sm={12} md={6} lg={3} key={k}>
                                    <div className='service_item2'>
                                        <Image src={STRAPI_IMAGE + card?.attributes?.Image?.data?.attributes?.url} alt='com' />
                                        <h4>{card?.attributes?.Title}</h4>
                                        <h6>{card?.attributes?.Description}</h6>
                                    </div>
                                </Col>
                            ))}
                        </Slider>
                    </div>

                </Row>

                <Row>
                    <Col xs={10} sm={8} md={4} lg={3} className='mx-auto my-5 '>
                        <a href="/pricing-plan" className='main btn btn-primary'>Create Free Account</a>
                        {/* <Link to='/register' className='main btn btn-primary'></Link> */}
                        {/* <Button ></Button> */}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default WhyChooseUs