import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { STRAPI_PRIVACY_POLICY, SEO_CONTENT_OF_PRIVACY_POLICY, StripeAxios } from '../../constants/strapi-api-constant'
import Loader from '../../components/Loader'
import Seo from '../../components/Seo'

const Privacy = () => {

  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [metaData, setMetaData] = useState({});


  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `Privacy Policy - ${process.env.REACT_APP_NAME}`;
    handleCallApi()
  }, []);


  const handleCallApi = async () => {

    await Promise.all([
      getPageContent(),
      getSeoContent()

    ])
  }

  const getSeoContent = async () => {
    try {
      setLoading(true)
      await StripeAxios.get(SEO_CONTENT_OF_PRIVACY_POLICY).then(res => {
        setLoading(false)
        const { data } = res?.data
        setMetaData(data?.attributes?.Seo);

      }).catch(err => { setLoading(false); console.log('seo privacy policy', err) });

    } catch (error) {
      setLoading(false)
      console.log("privacy policy seo", error);
    }

  }

  const getPageContent = async () => {
    try {
      setLoading(true)
      await StripeAxios.get(STRAPI_PRIVACY_POLICY).then(res => {
        setLoading(false)
        const { data } = res.data;
        setContent(data)
      })
        .catch(err => { setLoading(false); console.log("privacy", err) })

    } catch (error) {
      setLoading(false)
      console.log("api call err", error)
    }
  }


  return (
    <>
      {loading && <Loader />}

      <header className="header-inner">
        <h1> Privacy Policy</h1>
      </header>
      <Seo metaData={metaData} />

      <div className='terms_and_condition'>

        <Row className='pb-4'>
          <Col sm={12} md={10} lg={8} className='mx-auto'>


            {content?.map((item, i) => (
              <div className='term_box' key={i}>
                <h4>{i !== 0 && i + '. '} {item?.attributes?.Heading}</h4>
                {item?.attributes?.Description.map((des, d) => {
                  if (des?.type === "paragraph") {
                    return (<p key={d}>
                      {des?.children.map((pra) => {
                        if (pra?.type == "link") {
                          return <a href={pra?.url} target="_blank">{pra?.children[0]?.text}</a>
                        } else if (pra?.type == "text" && pra?.bold === true) {
                          return <strong>{pra.text}</strong>
                        } else if (pra?.type == "text") {
                          return pra.text;
                        }
                      })}
                    </p>)
                  } else if (des?.type === "list") {
                    return (
                      <ul>
                        {des?.children?.map((li, l) => {
                          return (<li key={l}>{li?.children[0]?.text}</li>)
                        })}
                      </ul>
                    )
                  }
                })}
              </div>
            ))}
          </Col>
        </Row>

      </div>
    </>
  )
}


export default Privacy