import React from 'react'

const PowerOfPredictive = ({ data }) => {
    return (
        <>
            <div className="row justify-context-center">
                <div className="col text-center">
                    <span className="label-title">INTRO</span>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8">
                    <h1 className="title my-4">{data?.Heading}</h1>
                </div>
            </div>

            <div className="container trust-by gray-theme">
                <div className="row justify-context-center">
                    <div className="col">
                        {data?.VideoURL &&
                            <iframe src={data?.VideoURL} title="How to Access Pre-Foreclosure &amp; Likely-to-Default (Propensity Data) on Skipify.ai" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        }
                    </div>
                    <div className="col-md-12 col-lg-5 align-self-center">
                        {data?.Description.map((item, i) => {
                            if (item?.type === "paragraph") {
                                return <h3 className='mb-3 ps-4 powerTitle' key={i}>{item?.children[0]?.text}</h3>
                            }
                        })}
                    </div>
                </div>
            </div>

            <div className="sep-vertical"></div>
        </>
    )
}

export default PowerOfPredictive