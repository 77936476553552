import React from 'react'
import { Link } from 'react-router-dom'

const Subscription = ({ data }) => {
    return (
        <>
            {/* newLetter */}
            <div className="container newsletter">
                <div className="row justify-content-center">
                    <div className="col-md-12 col-lg-9 col align-self-center ">
                        <h4 className='text-gray800 mb-2'>{data?.Heading}</h4>
                        <p className='subText'>{data?.Description}</p>
                    </div>

                    <div className='col-xs-12 col-md-12 col-lg-2 align-self-center'>
                        <div className="actions">
                            <Link to="/pricing-plan" className=" btn button-anchor wd-100 mt-3">Get Started</Link>

                        </div>
                    </div>

                </div>
            </div>
            <div className="sep-vertical"></div>
        </>
    )
}

export default Subscription