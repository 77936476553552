import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { STRAPI_IMAGE } from '../../../constants/strapi-api-constant'

const FeatureItem = ({ feature }) => {

    return (
        <>
            {feature?.DesignType === "DivTop" ?
                <div className="box-feature">
                    <div className="row justify-context-between">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <h1>{feature?.Heading}</h1>
                            <LazyLoadImage
                                src={STRAPI_IMAGE + feature?.Image?.data?.attributes?.url}
                                effect="blur"
                                alt={feature?.Image?.data?.attributes?.name}
                                className='rounded-4'
                            />
                            <p className="mt-2 mb-0">{feature?.Description}</p>
                        </div>
                    </div>
                </div>
                : feature?.DesignType === "DivLeft" ?
                    <div className="box-feature">
                        <div className="row justify-context-between">
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 align-self-center">
                                <LazyLoadImage
                                    src={STRAPI_IMAGE + feature?.Image?.data?.attributes?.url}
                                    effect="blur"
                                    className='rounded-4'
                                    alt={feature?.Image?.data?.attributes?.name}
                                />
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 col-xl-8 align-self-center">
                                <h1 className='my-2'>{feature?.Heading}</h1>
                                <p>{feature?.Description}</p>
                            </div>
                        </div>
                    </div>
                    : feature?.DesignType === "DivRight" ?
                        <div className="box-feature ">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 col-xl-8 col-sm-12 align-self-center">
                                    <h1 className="my-md-2">{feature?.Heading}</h1>
                                    <p>{feature?.Description}</p>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                    <LazyLoadImage
                                        src={STRAPI_IMAGE + feature?.Image?.data?.attributes?.url}
                                        effect="blur"
                                        className='rounded-4'
                                        alt={feature?.Image?.data?.attributes?.name}
                                    />
                                </div>
                            </div>
                        </div>
                        : feature?.DesignType === "DivRightLarge" ?
                            <div className="box-feature ">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-sm-12 ">
                                        <h1 className="mb-5 mt-3">{feature?.Heading}</h1>
                                        <p>{feature?.Description}</p>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <LazyLoadImage
                                            src={STRAPI_IMAGE + feature?.Image?.data?.attributes?.url}
                                            effect="blur"
                                            className='rounded-4'
                                            alt={feature?.Image?.data?.attributes?.name}
                                        />
                                    </div>
                                </div>
                            </div>
                            : ''

            }

        </>
    )
}

export default FeatureItem