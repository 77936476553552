import React from 'react'
import { STRAPI_IMAGE } from '../constants/strapi-api-constant'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import nextImgIcon from "./../assets/new-homepage/img/next.png"
const NewHomeBlogItem = ({ blog }) => {
    const history = useNavigate()
    const { pathname } = useLocation();
    const handleRedirect = () => {
        localStorage.removeItem('blogSeo')
        localStorage.setItem('blogSeo', JSON.stringify(blog?.attributes?.Seo))
        history(`/blog/${blog?.attributes?.slug}`)
    }
    return (
        <Link to={`/blog/${blog?.attributes?.slug}`}>
            <div div className="box-ai" onClick={() => handleRedirect()}>

                <img className="post-image" src={STRAPI_IMAGE + blog?.attributes?.Image?.data?.attributes?.url} alt='' />
                <h6>{blog?.attributes?.Title}</h6>
                <span><img className="icon-next" src={nextImgIcon} alt='' /></span>
            </div>
        </Link >

    )
}

export default NewHomeBlogItem