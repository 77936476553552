import { BiCalendar } from "react-icons/bi";
import { AiOutlineFolderOpen } from "react-icons/ai";
// import { BiUser } from "react-icons/bi";
import React, { useEffect, useState } from 'react'
import { SEO_CONTENT_OF_BLOG, STRAPI_IMAGE, StripeAxios, STRAPI_API_HOST } from '../../constants/strapi-api-constant'
import { Col, Row } from 'react-bootstrap'
import BlogItem from '../../components/BlogItem'
import Loader from '../../components/Loader'
import { useNavigate } from 'react-router-dom'
import moment from "moment";
import { LazyLoadImage } from "react-lazy-load-image-component";
import NoRecordsFound from "../../components/NoRecordsFound";
import Seo from "../../components/Seo";



const BlogsPage = () => {
    const history = useNavigate()
    const [content, setContent] = useState([]);
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState({})
    const [metaData, setMetaData] = useState({});

    useEffect(() => {
        handleCallApi()
        document.title = `Blogs - ${process.env.REACT_APP_NAME}`;
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        getPageContent()
        window.scrollTo(0, 0);
    }, [pagination?.page])

    // pagination
    const handleCallApi = async () => {
        try {
            setLoading(true);
            await Promise.all([
                getPageContent(),
                getSeoContent()
            ]);



        } catch (error) {
            console.error("Error in API calls:", error);
        } finally {
            setLoading(false);
        }
    };


    const getPageContent = async () => {
        try {
            const res = await StripeAxios.get(`${STRAPI_API_HOST}/blogs?populate=*&pagination[page]=${pagination?.page || 1}&pagination[pageSize]=${pagination?.pageSize || 21}&sort[0]=publishedAt:desc`);
            if (res && res?.data) {
                const { data, meta } = res.data;
                setPagination(meta?.pagination);
                setContent(data);
                return data;
            }
        } catch (error) {
            console.log("Blogs API error:", error);
            throw error;
        }
    };

    const getSeoContent = async () => {
        try {
            const response = await StripeAxios.get(SEO_CONTENT_OF_BLOG);
            if (response && response?.data) {
                const { data } = response?.data;
                setMetaData(data?.attributes?.Seo);
                return data?.attributes?.Seo;
            }
        } catch (error) {
            console.log("Blogs SEO error:", error);
            throw error;
        }
    };



    const handleRedirect = (blog) => {
        localStorage.removeItem('blogSeo')
        localStorage.setItem('blogSeo', JSON.stringify(blog?.attributes?.Seo))
        history(`/blog/${blog?.attributes?.slug}`)
    }


    const paginationList = () => {
        const items = [];
        for (let index = 0; index < pagination?.pageCount; index++) {
            let count = index + 1;
            items.push(<li key={index} className={`${count == pagination.page ? 'active' : ''}`} onClick={() => handleClick(count)}>{count}</li>);
        }
        return items;
    }

    const handlePrevious = () => {
        setPagination({ ...pagination, page: pagination?.page - 1 });
    }

    const handleNext = () => {
        setPagination({ ...pagination, page: pagination?.page + 1 });
    }
    const handleClick = (i) => {
        setPagination({ ...pagination, page: i });
    }
    return (
        <div>
            {loading && <Loader />}
            <Seo metaData={metaData} />

            <div className='blogs_section'>

                {!loading && <>
                    {!loading && content?.length === 0 ?
                        <NoRecordsFound />
                        :

                        <Row>
                            <Col xs={12} sm={12} md={10} lg={10} xl={10} xxl={8} className='mx-auto'>
                                <div className='top_blog_section'>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={6} className="d-flex justify-content-center align-items-center" >
                                            {/* <Link to={`/blog/${content[0]?.attributes?.slug}`}> */}
                                            {content[0]?.attributes?.Image ?
                                                <LazyLoadImage
                                                    src={STRAPI_IMAGE + content[0]?.attributes?.Image?.data?.attributes?.url}
                                                    PlaceholderSrc={STRAPI_IMAGE + content[0]?.attributes?.Image?.data?.attributes?.url}
                                                    effect="blur"
                                                    className='img-fluid'
                                                    alt="Image Alt"
                                                    onClick={() => handleRedirect(content[0])}
                                                />
                                                : ''

                                            }

                                            {/* <Image src={STRAPI_IMAGE + content[0]?.attributes?.Image?.data?.attributes?.url} className='img-fluid' onClick={() => handleRedirect(content[0])} /> */}
                                            {/* </Link> */}

                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                                            <div className='blog_top_text'>
                                                {content[0] &&
                                                    <ul className="auther_list">
                                                        {/* <li>
                                                <BiUser />
                                                <p> {content[0]?.attributes?.Author}</p>
                                            </li> */}
                                                        <li>
                                                            <BiCalendar />
                                                            <p>Published on {moment(content[0]?.attributes?.publishedAt).format('MMMM  D , YYYY')}</p>
                                                        </li>
                                                        <li>
                                                            <AiOutlineFolderOpen />
                                                            <p> Blogs | {content[0]?.attributes?.Category}</p>
                                                        </li>

                                                    </ul>

                                                }

                                                {/* <Link to={`/blog/${content[0]?.attributes?.slug}`}> */}
                                                <h4 className='title_blogs' onClick={() => handleRedirect(content[0])}>{content[0]?.attributes?.Title}</h4>
                                                {/* </Link> */}
                                                <p>{content[0]?.attributes?.Summary}</p>

                                            </div>
                                        </Col>

                                    </Row>
                                </div>

                                <Row className='d-flex justify-content-between my-5'>
                                    <Col md={4}>
                                        <h4 className='title_blogs'>Latest Blogs</h4>
                                    </Col>

                                </Row>
                                <Row className='d-flex my-5'>
                                    {content?.map((blog, b) => (
                                        <Col xs={12} sm={12} md={6} lg={6} xl={4} key={b} className='mb-3'>
                                            <BlogItem blog={blog} />
                                        </Col>
                                    ))}
                                </Row>
                                <Row>
                                    <Col>
                                        <ul className="pagination_navigation">
                                            {pagination?.page == 1 ?
                                                <li className="previous disabled">{'< Previous'}</li>
                                                :
                                                <li onClick={handlePrevious}>{'< Previous'}</li>
                                            }
                                            {paginationList()}

                                            {pagination?.page == pagination?.pageCount ?
                                                <li className="disabled next">{' Next >'}</li>
                                                :
                                                <li onClick={handleNext}>{' Next >'}</li>
                                            }

                                        </ul>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    }
                </>
                }


            </div>
        </div>
    )
}

export default BlogsPage