// import Headers from "./../../components/NewHomepage/Headers";
// import Footer from "./../../components/NewHomepage/footer";
import { Button, Modal } from 'react-bootstrap';
import React, { useEffect, useState } from "react";

import {
    StripeAxios,
    STRAPI_IMAGE,
    NEW_HERO_PAGE,
    NEW_LEAD_GENERATION,
    NEW_MAX_LEAD_GENERATION,
    NEW_WHY_WE_STAND_OUT,
    NEW_PAGE_REVIEWS,
    NEW_MINI_SLIDER,
    NEW_BLOG_UPDATE,
    NEW_VIDEOSECTION,
    NEW_STRAPI_BLOGS,
    SEO_CONTENT_OF_HOME_PAGE
} from '../../constants/strapi-api-constant';

import './../../assets/new-homepage/css/mystyle.css';
import check from "./../../assets/new-homepage/img/check.png";
import NewsLetter from './NewsLetter';

import { useNavigate } from 'react-router-dom';
import NewHomeBlogItem from '../../components/NewHomeBlogItem';
import Seo from '../../components/Seo';
import ReactPlayer from 'react-player';


const NewHomepage = () => {
    const navigate = useNavigate();

    const [heroSection, setHeroSection] = useState({});
    const [heroSectionListing, setHeroSectionListing] = useState([]);

    const [leadSection, setLeadSection] = useState({});

    const [maxLeadSection, setMaxLeadSection] = useState({});
    const [maxLeadSectionListing, setMaxLeadSectionListing] = useState([]);

    const [newWhyWeStandOut, setNewWhyWeStandOut] = useState({});
    const [headNewWhyWeStandSpan, setHeadNewWhyWeStandSpan] = useState('');
    const [headNewWhyWeStandText, setHeadNewWhyWeStandText] = useState('');
    const [featureNewWhyWeStand, setFeatureNewWhyWeStand] = useState([]);

    const [newPageReview, setNewPageReview] = useState({});
    const [headNewPageReviewSpan, setHeadNewPageReviewSpan] = useState('');
    const [headNewPageReviewText, setHeadNewPageReviewText] = useState('');
    const [headNewPageReviewText2, setHeadNewPageReviewText2] = useState('');
    const [newTestimonial, setNewTestimonial] = useState([]);

    const [newPageMiniSlider, setNewPageMiniSlider] = useState({});

    const [newBlogUpdate, setNewBlogUpdate] = useState({});
    const [newBlogItems, setNewBlogItems] = useState([]);


    const [newVideoSection, setNewVideoSection] = useState({});
    const [newVideoSectionHeading, setNewVideoSectionHeading] = useState('');
    const [newVideoSectionPara, setNewVideoSectionPara] = useState('');
    const [newVideoSectionHighlight, setNewVideoSectionHighlight] = useState('');
    const [newSetMegaVideoUrl, setNewMegaVideoUrl] = useState('');
    const [newSetVideoUrl, setNewVideoUrl] = useState('');
    const [newSetVideoHeading, setNewVideoHeading] = useState('');

    const [metaData, setMetaData] = useState({});



    useEffect(() => {
        getNewHeroSection();
        getNewLeadGenerationSection();
        getNewMaxLeadGenerationSection();
        getNewWhyWeStandOut();
        getNewPageReviews();
        getNewMiniSlider();
        getBlogUpdate();
        getNewVideoSection();
        getHomePageSeoContent();
    }, []);


    const getNewHeroSection = async () => {
        try {
            // setLoading(true)
            await StripeAxios.get(NEW_HERO_PAGE).then(res => {

                const { data } = res.data;
                setHeroSection(data?.attributes);
                let contentLi = data?.attributes?.listingkey;
                setHeroSectionListing(contentLi.split('\n'));

            }).catch(err => {
                console.log('New Homepage =>', err)
            });
        } catch (error) {
            console.log("New Homepage =>", error);
        }

    }


    const getNewLeadGenerationSection = async () => {
        try {
            // setLoading(true)
            await StripeAxios.get(NEW_LEAD_GENERATION).then(res => {
                const { data } = res.data;
                setLeadSection(data?.attributes);
            }).catch(err => {
                console.log('New Homepage Lead Generation =>', err)
            });
        } catch (error) {
            console.log("New Homepage Lead Generation=>", error);
        }
    }


    const getNewMaxLeadGenerationSection = async () => {
        try {
            // setLoading(true)
            await StripeAxios.get(NEW_MAX_LEAD_GENERATION).then(res => {
                const { data } = res.data;
                setMaxLeadSection(data?.attributes);
                let contentLi = data?.attributes?.listing;
                setMaxLeadSectionListing(contentLi.split('\n'));
            }).catch(err => {
                console.log('New Homepage MAX Lead Generation =>', err)
            });
        } catch (error) {
            console.log("New Homepage MAX Lead Generation=>", error);
        }
    }


    const getNewWhyWeStandOut = async () => {
        try {
            // setLoading(true)
            await StripeAxios.get(NEW_WHY_WE_STAND_OUT).then(res => {
                const { data } = res.data;
                setNewWhyWeStandOut(data?.attributes);
                setHeadNewWhyWeStandSpan(data?.attributes.Heading[0].children[0].text)
                setHeadNewWhyWeStandText(data?.attributes.Heading[0].children[1].text)
                setFeatureNewWhyWeStand(data?.attributes.Features);
            }).catch(err => {
                console.log('New Why We Stand Out =>', err)
            });
        } catch (error) {
            console.log("New Why We Stand Out  =>", error);
        }
    }


    const getNewPageReviews = async () => {
        try {
            // setLoading(true)
            await StripeAxios.get(NEW_PAGE_REVIEWS).then(res => {
                const { data } = res.data;
                setNewPageReview(data?.attributes);
                setHeadNewPageReviewSpan(data?.attributes.Heading[0].children[1].text)
                setHeadNewPageReviewText(data?.attributes.Heading[0].children[0].text)
                setHeadNewPageReviewText2(data?.attributes.Heading[0].children[2].text)
                setNewTestimonial(data?.attributes.Client);
            }).catch(err => {
                console.log('New PAGE REVIEW =>', err)
            });
        } catch (error) {
            console.log("New PAGE REVIEW  =>", error);
        }
    }


    const getNewMiniSlider = async () => {
        try {
            // setLoading(true)
            await StripeAxios.get(NEW_MINI_SLIDER).then(res => {
                const { data } = res.data;
                setNewPageMiniSlider(data?.attributes);
            }).catch(err => {
                console.log('New Mini Slider Section =>', err)
            });
        } catch (error) {
            console.log("New Mini Slider Section  =>", error);
        }
    }


    const getBlogUpdate = async () => {
        try {
            // setLoading(true)
            const [newHomeData, blogsLimit] = await Promise.all([
                StripeAxios.get(NEW_BLOG_UPDATE),
                StripeAxios.get(NEW_STRAPI_BLOGS),
            ]);

            if (newHomeData && newHomeData?.data && newHomeData?.status == 200) {
                const { data } = newHomeData?.data;
                setNewBlogUpdate(data?.attributes);
            }
            if (blogsLimit && blogsLimit?.status == 200) {
                const { data } = blogsLimit?.data;
                setNewBlogItems(data);
            }
        } catch (error) {
            console.log("New Blog Update  =>", error);
        }
    }
    const getNewVideoSection = async () => {
        try {
            // setLoading(true)
            await StripeAxios.get(NEW_VIDEOSECTION).then(res => {
                const { data } = res.data;
                setNewVideoSection(data?.attributes);

                setNewVideoSectionHeading(data?.attributes.MegaVideoSection.VideoWithDetailSection.Description[0].children[0].text);
                setNewVideoSectionPara(data?.attributes.MegaVideoSection.VideoWithDetailSection.Description[2].children[0].text);
                setNewVideoSectionHighlight(data?.attributes.MegaVideoSection.VideoWithDetailSection.Description[3].children[0].text);

                setNewMegaVideoUrl(data?.attributes.MegaVideoSection.VideoWithDetailSection.URLVideo);
                setNewVideoUrl(data?.attributes.MegaVideoSection.URLVideo);

                setNewVideoHeading(data?.attributes.MegaVideoSection.VideoWithDetailSection.Heading)

            }).catch(err => {
                console.log('New Video Section =>', err)
            });
        } catch (error) {
            console.log("New Video Section  =>", error);
        }
    }

    const getHomePageSeoContent = async () => {
        try {
            // setLoading(true)
            await StripeAxios.get(SEO_CONTENT_OF_HOME_PAGE).then(res => {
                const { data } = res.data;
                setMetaData(data?.attributes?.Seo)

            }).catch(err => {
                console.log('Home page Seo Api call', err)
            });

        } catch (error) {
            console.log("Home page Seo Api call", error);
        }
    }


    const [show, setShow] = useState(false);
    const [url, setUrl] = useState('');

    const handleClose = () => { setShow(false); setUrl('') };
    const handleShow = (url) => { setUrl(url); setShow(true) };



    return <>
        <div className="container ai-power-dashboard">
            <Seo metaData={metaData} />

            <Modal show={show} onHide={handleClose} className='videoModal' centered size="xl">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    {url === 1 ?
                        <iframe className='mega-iframe' src="https://www.youtube.com/embed/taAUgiQmBIg?si=vOtsgtAGJE2NUvHQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        :
                        <iframe className='mega-iframe' src="https://www.youtube.com/embed/i6i0z3V7iY8?si=NYNmaLWIufhCGI2e" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    }
                </Modal.Body>
            </Modal>
            <div className="grid-img">
                <div className="row justify-context-between py-4">

                    <div className="col-lg-6 col-md-12 align-self-center gradient-white">

                        <h1 className="heading">{heroSection?.heading}</h1>
                        <div className="listing">
                            <ul>
                                {heroSectionListing.map((list) => <>
                                    <li>
                                        <span><img src={check} alt='' /></span>
                                        {list}
                                    </li>
                                </>)}
                            </ul>
                        </div>
                        <div className="actions">
                            <div className="row justify-context-start">
                                <div className="col">
                                    <button className="primary" onClick={() => navigate('/pricing-plan')}>Sign Up for Free</button>
                                    <button className="secondary" onClick={() => handleShow(1)}>Watch Tutorial</button>
                                    {/* <a href='#area-video' className="btn btn-secondary secondary">Watch Tutorial</a> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        {heroSection?.megaslideimage && <img className="slide-image-wall" src={STRAPI_IMAGE + heroSection?.megaslideimage?.data?.attributes?.url} />}


                    </div>
                </div>
            </div>

            <div className="area-video mt-4" id='area-video'>
                <div className="row justify-context-between">
                    <div className="col">
                        <iframe className="mega-iframe" src={newSetVideoUrl} title="Revolutionize Your Real Estate Business with Skipify.ai skiptracing - Lead Generation Made Easy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                </div>
            </div>

            <div className="sep-vertical"></div>

            <div className="feature">
                <div className="row justify-context-center">
                    <div className="col text-center">
                        <span className="label-title">FEATURES</span>
                    </div>
                </div>
                <div className="row justify-context-center">
                    <div className="col">
                        <h1 className="title my-4">{leadSection?.Heading}</h1>
                    </div>
                </div>

                {/* For Desktop View */}
                <div className="row justify-context-between md-view">
                    <div className="col-lg-6 align-self-center col-md-12">
                        <div className="row">
                            <div className="col-12">
                                <div className="box-feature">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12 align-self-center">
                                            <h1 className="mb-4">{leadSection?.FieldHeading1}</h1>
                                            <p className="mt-4">{leadSection?.FieldDetail1}</p>
                                        </div>
                                        <div className="col-md-6 col-sm-12 align-self-center">
                                            <img src={STRAPI_IMAGE + leadSection?.FieldImage1?.data?.attributes?.url} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="box-feature">
                                    <div className="row justify-context-between">
                                        <div className="col-md-4 col-sm-12 align-self-center">
                                            <img src={STRAPI_IMAGE + leadSection?.FieldImage3?.data?.attributes?.url} />
                                        </div>
                                        <div className="col-md-8 col-sm-12 align-self-center">
                                            <h1 className='mb-4'>{leadSection?.FieldHeading3}</h1>
                                            <p>{leadSection?.FieldDetail3}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 align-self-center col-mg-12">
                        <div className="row">
                            <div className="col-12 align-self-center">
                                <div className="box-feature">
                                    <div className="row">
                                        <div className="col-md-8 col-sm-12 align-self-center">
                                            <h1 className="mb-2">{leadSection?.FieldHeading2}</h1>
                                            <p className="wd-92">{leadSection?.FieldDetail2}</p>
                                        </div>
                                        <div className="col-md-4 col-sm-12">
                                            <img src={STRAPI_IMAGE + leadSection?.FieldImage2?.data?.attributes?.url} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="box-feature">
                                    <div className="row justify-context-between">
                                        <div className="col">
                                            <h1>{leadSection?.FieldHeading4}</h1>
                                            <img src={STRAPI_IMAGE + leadSection?.FieldImage4?.data?.attributes?.url} />
                                            <p className="mt-2 mb-0">{leadSection?.FieldDetail4}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* For Mobile View */}
                <div className="row justify-context-between sm-view">
                    <div className="col-md-6 align-self-center col-sm-12">
                        <div className="row">
                            <div className="col-12">
                                <div className="box-feature">
                                    <div className="row">
                                        <div className="col-sm-12 align-self-center">
                                            <img src={STRAPI_IMAGE + leadSection?.FieldImage1?.data?.attributes?.url} />
                                            <h1 className="mb-4 mt-4">{leadSection?.FieldHeading1}</h1>
                                            <p className="mt-4">{leadSection?.FieldDetail1}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="box-feature">
                                    <div className="row justify-context-between">
                                        <div className="col-sm-12 align-self-center">
                                            <img src={STRAPI_IMAGE + leadSection?.FieldImage3?.data?.attributes?.url} />
                                            <h1 className='mb-4 mt-4'>{leadSection?.FieldHeading3}</h1>
                                            <p>{leadSection?.FieldDetail3}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 align-self-center col-sm-12">
                        <div className="row">
                            <div className="col-12 align-self-center">
                                <div className="box-feature">
                                    <div className="row">
                                        <div className="col-sm-12 align-self-center">
                                            <img src={STRAPI_IMAGE + leadSection?.FieldImage2?.data?.attributes?.url} />
                                            <h1 className="mb-2 mt-4">{leadSection?.FieldHeading2}</h1>
                                            <p className="wd-92">{leadSection?.FieldDetail2}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="box-feature">
                                    <div className="row justify-context-between">
                                        <div className="col">
                                            <img src={STRAPI_IMAGE + leadSection?.FieldImage4?.data?.attributes?.url} />
                                            <h1 className='mb-2 mt-4'>{leadSection?.FieldHeading4}</h1>
                                            <p className="mt-2 mb-0">{leadSection?.FieldDetail4}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sep-vertical"></div>

            <div className="container trust-by">
                <div className="row justify-context-center">
                    <div className="col-md-12 col-lg-6 bd-r-grey">
                        <h1>{newSetVideoHeading}</h1>
                    </div>
                    <div className="col-md-12 col-lg-6">
                        <h2 className='mb-3'>{newVideoSectionHeading}<br></br></h2>
                        <b>{newVideoSectionPara}</b><br></br>
                        <span className="light-col">{newVideoSectionHighlight}</span>
                    </div>
                </div>
                <div className="row justify-context-center">
                    <div className="col mt-4">
                        <iframe className="mega-iframe" src={newSetMegaVideoUrl} title="Revolutionize Your Real Estate Business with Skipify.ai skiptracing - Lead Generation Made Easy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                </div>
            </div>

            <div className="sep-vertical"></div>

            <div className="container feature">
                <div className="row justify-content-center">
                    <div className="col text-center">
                        <span className="label-title">HOW TO GET STARTED</span>
                    </div>
                </div>
                <div className="row justify-content-center mt-4">
                    <div className="col-lg-10 col">
                        <h1 className="title mb-4">{maxLeadSection?.heading}</h1>
                    </div>
                </div>
                <div className="row justify-content-between">
                    <div className="col-lg-6 align-self-center col-md-12">
                        <div className="grid-img height-maxi-img">
                            <img className="maxim-img" src={STRAPI_IMAGE + maxLeadSection?.sectionImg?.data?.attributes?.url} alt='' />
                        </div>
                    </div>
                    <div className="col-lg-6 align-self-center col-md-12">
                        <ul className="maximize-list">
                            {maxLeadSectionListing.map((list, i) => <>
                                <li>
                                    <span className="orange">{i + 1}</span>
                                    <p className="align-self-center">{list}</p>
                                </li>
                            </>)}
                        </ul>
                    </div>
                </div>
                <div className="row justify-content-center mt-4 mb-4">
                    <div className="actions">

                        <button className="primary center-btn-casual" onClick={() => navigate('/pricing-plan')}>Get Started Today</button>
                    </div>
                </div>



            </div>

            <div className="sep-vertical"></div>

            <div className="optimize-cont">
                <div className="row justify-content-center">
                    <div className="col text-left mb-4">
                        <span className="label-title">WHY WE STAND OUT</span>
                    </div>
                </div>
                <div className="row justify-content-between optimize-sec">
                    <div className="col-md-7 align-self-center col-sm-12">
                        <h1 className="left-sec">
                            <span>{headNewWhyWeStandSpan}</span>
                            {headNewWhyWeStandText}
                        </h1>
                    </div>
                    <div className="col-md-5 align-self-center col-sm-12 right-sec">
                        <p>
                            {newWhyWeStandOut?.description}<br></br>
                            <span>{newWhyWeStandOut?.DescriptionBreakdown}</span>
                        </p>
                    </div>

                    <hr></hr>

                    <div className="row category-opt justify-content-center">
                        {featureNewWhyWeStand.map((list, i) => <>
                            <div className="col-lg-4 col-md-6 col-sm-12 box-category">
                                <h1>{list.Heading}</h1>
                                <p>{list.Description}</p>
                            </div>
                        </>)}

                    </div>
                </div>



            </div>

            <div className="sep-vertical"></div>

            <div className="container optimize-cont bd-0 pd-0">
                <div className="row justify-content-center optimize-sec">
                    <div className="col-lg-8 align-self-center col-md-12  mb-4">
                        <h2 className="left-sec text-center mb-4">{headNewPageReviewText} <span>{headNewPageReviewSpan}</span>
                            {headNewPageReviewText2}</h2>
                    </div>

                    <div className="row justify-content-center testimony-ai-power">
                        {newTestimonial.map((list, i) => <>
                            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                <div className="box-category">
                                    <h6 className="height-5">{list.Feedback}</h6>
                                    <div className="feedback-auth">
                                        <img src={STRAPI_IMAGE + list?.Profile?.data?.attributes?.url} />
                                        <div className="box">
                                            <h6>{list.Name}</h6>
                                            <p>{list.Designation}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>)}
                    </div>
                </div>

                <div className="sep-vertical"></div>

                <div className="row justify-content-between property-chart">
                    <div className="col-lg-6 col-md-12 mb-md-4 align-self-center">
                        <h1 className="mb-4">{newPageMiniSlider?.Heading}</h1>

                        <div className="actions mt-8">
                            <div className="row justify-context-start">
                                <div className="col">
                                    <button className="secondary light-yellow-theme" onClick={() => navigate('/pricing-plan')}>Sign Up for Free</button>
                                    <button className="secondary" onClick={() => handleShow(2)}>Watch Tutorial</button>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-6 col-md-12 align-self-center">
                        <img className="prop-chart" src={STRAPI_IMAGE + newPageMiniSlider?.SlideImage?.data?.attributes?.url} />
                    </div>
                </div>
            </div>

            <div className="sep-vertical"></div>

            <div className="">
                <div className="row justify-content-center">
                    <div className="col text-center">
                        <span className="label-title">BLOG AND UPDATES</span>
                    </div>
                </div>
                <div className="row justify-content-center mt-4">
                    <div className="col">
                        <h1 className="title mb-4">{newBlogUpdate?.Heading}</h1>
                    </div>
                </div>
                <div className="row justify-content-center blogs-ai">
                    {newBlogItems.map((list, i) => <>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <NewHomeBlogItem blog={list} />
                        </div>
                    </>)}

                </div>
            </div>

            <div className="sep-vertical"></div>
            {/* newLetter */}
            <NewsLetter />
            <div className="sep-vertical"></div>


        </div>

        {/* <Footer></Footer> */}
    </>
}




export default NewHomepage;