import { Route, Routes } from "react-router-dom"
import Layout from "../components/Layout"


// Pages
import HomePage from "../pages/home"
import AboutUs from "../pages/aboutus"
import Pricing from "../pages/pricing"
import ContactSupport from "../pages/contactSupport"
import PersonalInfo from "../pages/personalInfo"
import TermsCondition from "../pages/terms"
import Privacy from "../pages/privacy"
import ContactSale from "../pages/contactSale"
import Blogs from "../pages/BlogsPage"
import NotFound from "../pages/notfound"
import BlogDetail from "../pages/BlogsPage/BlogDetail"
import SkipifyTv from "../pages/skipifyTv"
import SkipifyDetail from "../pages/skipifyTv/SkipifyDetail"
import Affiliate from "../pages/affiliate"
import FreeDownloadForm from "../pages/skipifyTv/FreeDownloadForm"
import NewHomepage from "../pages/newHomepage";
import PreForeclosre from "../pages/preForeclosure/index";





const AllRoutes = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<NewHomepage title="Home" />} />
        {/* <Route path="/" element={<HomePage title="Home" />} /> */}
        <Route path="/about-us" element={<AboutUs title="About Us" />} />
        <Route path="/pricing-plan" element={<Pricing title="Pricing Plan" />} />
        <Route path="/contact-us" element={<ContactSupport title="Contact Support" />} />
        <Route path="/personal-info" element={<PersonalInfo title="Personal Information" />} />
        <Route path="/terms" element={<TermsCondition title="TermsCondition" />} />
        <Route path="/privacy-policy" element={<Privacy title="Privacy Policy" />} />
        <Route path="/contact-sales" element={<ContactSale title="Contact Sales" />} />
        <Route path="/blogs" element={<Blogs title="Blogs" />} />
        <Route path="/blog/:slug" element={<BlogDetail title="Blog Detail" />} />
        <Route path="/skipify-detail" element={<SkipifyDetail title="Skipify Detail" />} />
        <Route path="/skipify-u" element={<SkipifyTv title="Skipify-Tv" />} />
        <Route path="/affiliate" element={<Affiliate title="Affiliate" />} />
        <Route path="/free-download-form/:slug" element={<FreeDownloadForm title="Free Download Form" />} />
        <Route path="/404" element={<NotFound title="404" />} />
        <Route path="/*" element={<NotFound title="404" />} />
        <Route path="/pre-foreclosure" element={<PreForeclosre title="Pre Foreclosure" />} />

        {/* <Route path="/new-homepage" element={<NewHomepage title="Homepage" />} />  */}
      </Routes>
    </Layout>
  )
}


export default AllRoutes;
