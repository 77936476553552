import React from 'react'
import FeatureItem from './FeatureItem'

const FeatureForClosure = ({ features, heading }) => {
    return (
        <>
            <div className="feature">
                <div className="row justify-context-center">
                    <div className="col text-center">
                        <span className="label-title">Features</span>
                    </div>
                </div>
                <div className="row justify-context-center">
                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-8 col-xl-8 mx-auto">
                        <h1 className="title my-4">{heading}</h1>
                    </div>
                </div>

                <div className="row justify-context-between">
                    <div className="col-lg-6 align-self-center col-md-12">
                        <div className='row'>
                            {features?.map((feature, f) => {
                                if (f <= 1) {
                                    return (
                                        <div className="col-12" key={f}>
                                            <FeatureItem feature={feature} />
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </div>
                    <div className="col-lg-6 align-self-start col-md-12">

                        <div className='row'>
                            {features?.map((feature, f) => {
                                if (f > 1) {
                                    return (
                                        <div className="col-12" key={f}>
                                            <FeatureItem feature={feature} />
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </div>

                </div>
            </div>

            <div className="sep-vertical"></div>
        </>
    )
}

export default FeatureForClosure